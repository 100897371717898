import * as React from "react"
import {Box, Container, makeStyles, Typography} from '@material-ui/core'
import AvatarHeader from "src/components/AvatarHeader"

const Disclosure = () => {

  return (
    <main>
      <AvatarHeader/>
        <Box m={2}>
          <Typography>
            Products listed on this site may contain Amazon Affiliate links. I receive a small commision for any purchases made though these links.
          </Typography>
          <br/>
          <Typography>
            Affiliate relationships do not affect the products I choose to include in product lists or articles. Recommendations are made solely based on quality, performance, and other product experience factors.
          </Typography>
        </Box>

    </main>

  )
}

export default Disclosure